<template>
  <div class="contact-container">
    <div
      class="contact-form"
      :style="'height:' + swiperHeight + 'px'"
    >
      <div class="contact-title">
        劳务邦服务平台
      </div>
      <!-- pc端显示的表单 -->
      <div class="form">
        <el-input
          v-model="name"
          class="form-input"
          placeholder="姓名"
          size="large"
        >
          <svg
            slot="prefix"
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-xingming" />
          </svg>
        </el-input>
        <el-input
          v-model="email"
          class="form-input"
          placeholder="邮箱地址"
          size="large"
        >
          <svg
            slot="prefix"
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-youxiang" />
          </svg>
        </el-input>
        <el-input
          v-model="phone"
          class="form-input"
          placeholder="电话号码"
          size="large"
        >
          <svg
            slot="prefix"
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-dianhua" />
          </svg>
        </el-input>
        <el-button
          class="form-btn"
          @click="join"
        >
          联系我们
        </el-button>
      </div>
    </div>
    <!-- 移动端显示的表单 -->
    <div class="client-form">
      <el-input
        v-model="name"
        class="form-input"
        placeholder="姓名"
        size="large"
      >
        <svg
          slot="prefix"
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-xingming" />
        </svg>
      </el-input>
      <el-input
        v-model="email"
        class="form-input"
        placeholder="邮箱地址"
        size="large"
      >
        <svg
          slot="prefix"
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-youxiang" />
        </svg>
      </el-input>
      <el-input
        v-model="phone"
        class="form-input"
        placeholder="电话号码"
        size="large"
      >
        <svg
          slot="prefix"
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-dianhua" />
        </svg>
      </el-input>
      <el-button
        class="form-btn"
        @click="join"
      >
        联系我们
      </el-button>
    </div>
    <!-- 分公司列表 -->
    <div class="contact-corps">
      <div class="corps-label">
        联系方式
      </div>
      <div class="corps-list">
        <div
          v-for="(corp, index) in corps"
          :key="index"
          class="corp"
        >
          <div class="corp-name">
            <svg
              class="icon"
              aria-hidden="true"
            >
              <use xlink:href="#icon-address" />
            </svg>
            <span>{{ corp.name }}</span>
          </div>
          <p class="corp-address">
            {{ corp.address }}
          </p>
          <p class="corp-phone">
            电话：<span
              v-for="(item, idx) in corp.phone"
              :key="idx"
            >{{ item }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="divider-box" />
  </div>
</template>
<script>
export default {
  data () {
    return {
      swiperHeight: 0,
      clientWidth: 0,
      name: '',
      email: '',
      phone: '',
      corps: [
        {
          name: '总部',
          address: '上海市嘉定区安亭镇墨玉路28号1119室',
          phone: ['400-030-6588']
        },
        {
          name: '长沙商务中心',
          address: '长沙市雨花区树木岭路16号双铁兴苑1号综合楼15楼',
          phone: ['400-030-5180']
        }
      ]
    }
  },
  mounted () {
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    this.swiperHeight = Math.round(this.clientWidth / 4)
    window.addEventListener('resize', () => {
      this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
      this.swiperHeight = Math.round(this.clientWidth / 4)
    })
  },
  methods: {
    join () {
      if (this.name && this.email && this.phone) {
        let params = {
          name: this.name,
          email: this.email,
          phone: this.phone
        }
        this.$store.dispatch('join', params).then(res => {
          if (res === 'success') {
            this.$notify({
              title: '成功',
              message: '提交成功',
              type: 'success'
            })
            this.name = ''
            this.email = ''
            this.phone = ''
          }
        })
      } else {
        this.$alert('姓名，邮箱，电话号码均为必填', '温馨提示', {
          confirmButtonText: '确定'
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.contact-container {
  background: #fafafa;
}
.contact-form {
  width: 100%;
  background-image: url("../assets/Images/contact-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  position: relative;
  .contact-title {
    color: #fff;
    position: absolute;
    letter-spacing: 2px;
  }
  .form {
    display: flex;
    align-items: center;
    .form-btn {
      background: #297ed3;
      color: #fff;
      border: 1px solid #297ed3;
      letter-spacing: 1px;
    }
  }
}
.contact-corps {
  background: #fff;
  box-sizing: border-box;
  .corps-label {
    text-align: left;
    color: #297ed3;
    letter-spacing: 1px;
    box-sizing: border-box;
  }
  .corps-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .corp {
      text-align: left;
      box-sizing: border-box;
      .corp-name {
        display: flex;
        align-items: center;
        span {
          color: #297ed3;
          font-size: 16px;
          letter-spacing: 1px;
          margin-left: 5px;
        }
      }
      .corp-address {
        color: #777;
        font-size: 15px;
      }
      .corp-phone {
        color: #777;
        font-size: 15px;
        span {
          margin-right: 10px;
        }
      }
    }
  }
}
.divider-box {
  width: 100%;
  background: #fafafa;
}
/* -----------适应pc端(大屏幕)--------------- */
@media screen and (min-width: 1160px) {
  .contact-form {
    display: block;
    height: 628px;
    padding-top: 180px;
    .contact-title {
      font-size: 32px;
      top: 110px;
      left: 45%;
    }
    .form {
      margin-left: 340px;
      .form-input {
        width: 300px;
        margin-right: 10px;
      }
      .form-btn {
        width: 180px;
        font-size: 15px;
      }
    }
  }
  .client-form {
    display: none;
  }
  .contact-corps {
    width: 1200px;
    margin: 60px auto 0;
    padding: 0 50px;
    .corps-label {
      font-size: 20px;
      padding: 40px 0 15px 0;
    }
    .corps-list {
      padding-bottom: 60px;
      .corp {
        width: 42%;
        margin-top: 25px;
        .icon {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  .divider-box {
    height: 80px;
  }
}
/* -----------适应pc端(中屏幕)--------------- */
@media screen and (min-width: 700px) and (max-width: 1160px) {
  .contact-form {
    display: block;
    height: 428px;
    padding-top: 130px;
    .contact-title {
      font-size: 28px;
      top: 60px;
      left: 40%;
    }
    .form {
      margin-left: 50px;
      margin-right: 20px;
      .form-input {
        width: 260px;
        margin-right: 10px;
      }
      .form-btn {
        width: 150px;
        font-size: 14px;
      }
    }
  }
  .client-form {
    display: none;
  }
  .contact-corps {
    width: 100%;
    margin: 60px auto 0;
    padding: 0 25px;
    .corps-label {
      font-size: 20px;
      padding: 30px 0 15px 0;
    }
    .corps-list {
      padding-bottom: 50px;
      .corp {
        width: 48%;
        margin-top: 15px;
        .icon {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .divider-box {
    height: 80px;
  }
}
/* -------------适应移动端---------------- */
@media screen and (max-width: 700px) {
  .contact-form {
    // height: 250px;
    padding-top: 80px;
    .contact-title {
      font-size: 18px;
      top: 10%;
      left: 38%;
    }
    .form {
      display: none;
    }
  }
  .client-form {
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    .form-input {
      width: 300px;
      margin-bottom: 15px;
    }
    .form-btn {
      width: 240px;
      font-size: 14px;
      background: #297ed3;
      color: #fff;
      border: 1px solid #297ed3;
      letter-spacing: 1px;
    }
  }
  .contact-corps {
    width: 100%;
    margin: 40px auto 0;
    padding: 0 12px;
    .corps-label {
      font-size: 18px;
      padding: 20px 0 10px 0;
    }
    .corps-list {
      width: 100%;
      padding-bottom: 20px;
      .corp {
        width: 49%;
        margin-top: 10px;
        .icon {
          width: 18px;
          height: 18px;
        }
        .corp-phone {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .divider-box {
    height: 30px;
  }
}
/* -------------适应移动端(二)---------------- */
</style>
